import * as React from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Service from '../components/Service';
import ChildParent from '../media/child_mom.jpg';
import Children from '../media/child.jpg';
import Adults from '../media/journey_adventure_1.jpg';
import Adolescents from '../media/adolescent.jpg';
import AppointmentButton from '../components/AppointmentButton';
import { adolescentApproaches, adolescents, adultApproaches, adults, childParent, childParentApproaches, children, childrenApproaches, ServiceID } from '../util/Services';
import { useRef } from 'react';

const Services: React.FC = () => {
  const theme = useTheme();
  const isSmOrLess = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdOrLess = useMediaQuery(theme.breakpoints.down('md'));
  const infantSection = useRef<HTMLDivElement>(null);
  const childrenSection = useRef<HTMLDivElement>(null);
  const adolescentSection = useRef<HTMLDivElement>(null);
  const adultSection = useRef<HTMLDivElement>(null);
  return (
    <Grid container width={"100%"} height={'fit-content'} alignItems="center" justifyContent={'center'} padding={1}>
      <Grid item container width={"100%"} alignItems="center" justifyContent={'center'} padding={isMdOrLess ? 1 : 3} maxWidth={'lg'} >
        <Grid item>
          <Typography align={'center'} sx={{ fontFamily: 'Lora, serif'}} variant={'h4'}>
            Services
          </Typography>
        </Grid>
        {!isSmOrLess && (
          <Grid item className={"right"}>
            <AppointmentButton />
          </Grid>
        )}
      </Grid>
      <Grid container width={"100%"} alignItems="center" justifyContent={'center'} maxWidth={'lg'} >
        <Grid item xs={12} ref={infantSection}>
          <Service
            title={'Infant/Young Child & Parent'}
            description={'During infancy and early childhood (under 3), children begin to make sense of the world and develop relationships with those around them. Sometimes there can be strains and complexities to these relationships for the infant and/or caregiver which result in the following areas of:'}
            listItems={childParent}
            approachesTitle={'Therapeutic Approaches'}
            approaches={childParentApproaches}
            image={ChildParent}
            imageLeft={true}
            id={ServiceID.infant}
          />
        </Grid>
        <Grid item xs={12} ref={childrenSection}>
          <Service
            title={'Children'}
            listItems={children}
            description={'Similar to adults, children can struggle with the demands of everyday life, but without the skills and language to express themselves. This is when we start to see children showing us how they feel through their “big” behaviors. This might look like:'}
            approachesTitle={'Therapeutic Approaches'}
            approaches={childrenApproaches}
            image={Children}
            id={ServiceID.children}
          />
        </Grid>
        <Grid item xs={12} ref={adolescentSection}>
          <Service
            title={'Adolescents'}
            description={'Adolescents can be a complicated age, as they are not dependent children but still not quite independent adults. Adolescents are in the life phase of finding their identity and place within the world. As a therapist I can support them with the following:'}
            listItems={adolescents}
            approachesTitle={'Therapeutic Approaches'}
            approaches={adolescentApproaches}
            image={Adolescents}
            imageLeft={true}
            id={ServiceID.adolescent}
          />
        </Grid>
        <Grid item xs={12} ref={adultSection}>
          <Service
            title={'Adults'}
            listItems={adults}
            description={'Everyone has experienced their own journey with the ups and downs life has to offer. Sometimes we get stuck in those ruts and need the support of a trusting, authentic professional to find our ways out. I specializing in offering support to those impacted by:'}
            approachesTitle={'Therapeutic Approaches'}
            approaches={adultApproaches}
            image={Adults}
            id={ServiceID.adult}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Services;
