import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface SliceState {
    verification: string | null,
    googleUrl: string | null
}

const initialState: SliceState = {
    verification: null,
    googleUrl: 'https://www.google.com/maps/place/2510+Chili+Ave+Suite+5,+Rochester,+NY+14624'
};

export const slice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setVerification: (state, action: PayloadAction<string>) => {
            state.verification = action.payload;
        },
        setGoogleUrl: (state, action: PayloadAction<string>) => {
            state.googleUrl = action.payload;
        }
    }
});

export const selectVerification = (state: RootState) => state.app.verification;
export const selectGoogleUrl = (state: RootState) => state.app.googleUrl;
export const {
    setVerification,
    setGoogleUrl,
} = slice.actions;

export default slice.reducer;