import * as React from 'react';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    spwidgetWrap: {
        textAlign: 'center',
        textShadow: '0px 0px 3px #ffdbbe'
    },
    spwidgetButton: {
        width: '100%',
        maxWidth: '250px',
        display: 'inline-block',
        padding: '5px 15px',
        color: '#333333',
        background: '#f5d8c1',
        border: 0,
        lineHeight: "1.65",
        borderRadius: '4px',
        fontSize: '16px',
        fontWeight: 400,
        textDecoration: 'none',
        ':hover': {
            background: '#d15913',
        },
        ':active': {
            boxShadow: '0 1px 3px rgba(0, 0, 0, .15) inset',
        }
    }
  })
);
const AppointmentButton: React.FC = () => {
    const classes = useStyles();
    return (
        <div className={classes.spwidgetWrap}>
            <a href="https://julie-saraceno.clientsecure.me" className={classes.spwidgetButton}>
                Request Appointment
            </a>
        </div>
    );
}

export default AppointmentButton;
